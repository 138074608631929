/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// gatsby-browser.js


// This only works if in the modal page you add a
// Link to the homepage.
import wrapPage from "./wrap-page"
export const wrapPageElement = wrapPage
